var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('svg', {
    class: "fill-current  text-".concat(_vm.color),
    attrs: {
      "height": "".concat(_vm.height, "px"),
      "width": "".concat(_vm.width, "px"),
      "viewBox": "0 0 24 24",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  }, [_c('path', {
    attrs: {
      "d": "M9.5625 11.25H3.1875C2.73995 11.25 2.31072 11.0722 1.99426 10.7557C1.67779 10.4393 1.5 10.0101 1.5 9.5625V3.1875C1.5 2.73995 1.67779 2.31072 1.99426 1.99426C2.31072 1.67779 2.73995 1.5 3.1875 1.5H9.5625C10.0101 1.5 10.4393 1.67779 10.7557 1.99426C11.0722 2.31072 11.25 2.73995 11.25 3.1875V9.5625C11.25 10.0101 11.0722 10.4393 10.7557 10.7557C10.4393 11.0722 10.0101 11.25 9.5625 11.25ZM20.8125 11.25H14.4375C13.9899 11.25 13.5607 11.0722 13.2443 10.7557C12.9278 10.4393 12.75 10.0101 12.75 9.5625V3.1875C12.75 2.73995 12.9278 2.31072 13.2443 1.99426C13.5607 1.67779 13.9899 1.5 14.4375 1.5H20.8125C21.2601 1.5 21.6893 1.67779 22.0057 1.99426C22.3222 2.31072 22.5 2.73995 22.5 3.1875V9.5625C22.5 10.0101 22.3222 10.4393 22.0057 10.7557C21.6893 11.0722 21.2601 11.25 20.8125 11.25ZM9.5625 22.5H3.1875C2.73995 22.5 2.31072 22.3222 1.99426 22.0057C1.67779 21.6893 1.5 21.2601 1.5 20.8125V14.4375C1.5 13.9899 1.67779 13.5607 1.99426 13.2443C2.31072 12.9278 2.73995 12.75 3.1875 12.75H9.5625C10.0101 12.75 10.4393 12.9278 10.7557 13.2443C11.0722 13.5607 11.25 13.9899 11.25 14.4375V20.8125C11.25 21.2601 11.0722 21.6893 10.7557 22.0057C10.4393 22.3222 10.0101 22.5 9.5625 22.5ZM20.8125 22.5H14.4375C13.9899 22.5 13.5607 22.3222 13.2443 22.0057C12.9278 21.6893 12.75 21.2601 12.75 20.8125V14.4375C12.75 13.9899 12.9278 13.5607 13.2443 13.2443C13.5607 12.9278 13.9899 12.75 14.4375 12.75H20.8125C21.2601 12.75 21.6893 12.9278 22.0057 13.2443C22.3222 13.5607 22.5 13.9899 22.5 14.4375V20.8125C22.5 21.2601 22.3222 21.6893 22.0057 22.0057C21.6893 22.3222 21.2601 22.5 20.8125 22.5Z"
    }
  })]);
}
var staticRenderFns = []

export { render, staticRenderFns }